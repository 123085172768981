import {Button, FloatButton, Layout, message, notification, Result, Space, theme} from 'antd';
import React, {useState} from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import packageInfo from "../../../../package.json";
import HolisticoProposal from "@holistico/proposal/view/HolisticoProposal";
import HHeader from "@app/layout/ria/HHeader";
import UnderMaintenance from "@holistico/aux/UnderMaintanence";
import RecentProposals from "@holistico/proposal/recent/RecentProposals";
import Assets from "@root/site/Assets";
import Screener from "@root/site/Screener";
import ModelPortfolios from "@holistico/models/ModelPortfolios";
import {useAuth} from "@hooks/AuthHook";
import ProfileLayout from "@holistico/profile/ProfileLayout";
import Backtest from "@root/site/Backtest";
import Tutorial from "@holistico/tutorial/Tutorial";
import Supervision from "@root/site/Supervision";
import AdvisorIndexContents from "@holistico/welcome/AdvisorIndexContents";
import Notifications from "@app/layout/Notifications";
import Enrollment from "@holistico/proposal/new/Enrollment";
import {PortfolioScreener} from "@components/screener/AssetScreener";


const {Content, Footer} = Layout;


const HLayout = () => {
    const version = packageInfo.version;
    const navigate = useNavigate();

    const auth = useAuth();

    const ComplianceElement = () => {
        let rootElement = <Result
            status="403"
            title="403"
            subTitle="No supervisor priveleges."
            extra={<Button type="primary">Back Home</Button>}
        />
        if (auth?.isAdmin() || auth?.isSupervisor()) {
            rootElement = <Supervision/>
        }
        return rootElement
    }


    return (
        <Layout style={{
            minHeight: 750,
            background: '#f6f9fa'
        }}>
            <Notifications/>
            <HHeader/>
            <Content style={{
                backgroundImage: "none",
                background: "none",
                margin: "0 auto",
                width: "94%",
                maxWidth: 1500,
            }}>
                <Routes>
                    <Route path="" element={<Navigate to="dashboard" replace={true}/>}/>
                    <Route path="dashboard" element={<AdvisorIndexContents/>}/>
                    <Route path="proposals" element={<RecentProposals/>}/>
                    <Route path="proposals/new" element={<Enrollment/>}/>
                    <Route path="proposals/new/:id" element={<Enrollment/>}/>
                    <Route path="proposals/:id/*" element={<HolisticoProposal/>}/>
                    <Route path="assets" element={<Assets/>}/>
                    <Route path="screener/*" element={<Screener/>}/>
                    <Route path="profile/:id/*" element={<ProfileLayout/>}/>
                    <Route path="models" element={<ModelPortfolios/>}/>
                    <Route path="backtest/*" element={<Backtest/>}/>
                    <Route path={"compliance/*"} element={<ComplianceElement/>}/>
                    <Route path="tutorial/*" element={<Tutorial/>}/>
                    <Route path="user/report-templates" element={<UnderMaintenance/>}/>
                    <Route path="marketplace/*" element={<PortfolioScreener/>}/>
                    <Route path="*" element={
                        <Result
                            status="404"
                            title="404"
                            subTitle="Sorry, the page you visited does not exist."
                            extra={
                                <Button
                                    type="primary"
                                    onClick={() => navigate("")}
                                >
                                    Back Home
                                </Button>
                            }
                        />
                    }/>
                </Routes>
            </Content>
            <FloatButton.BackTop visibilityHeight={800} style={{
                bottom: 8,
                insetInlineEnd: 64,
            }}/>
            <Footer style={{textAlign: 'center'}}>
                Powered by Holistico Investment LLC
                ©{(new Date().getFullYear())}
                <br/><small> v. {version}</small>
            </Footer>
        </Layout>
    );
}

export default HLayout
