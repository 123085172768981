import React, {useState} from "react";
import dayjs from "dayjs";
import {
    Button,
    Card,
    ConfigProvider,
    Divider,
    Flex,
    message, Modal,
    Popconfirm, Tag,
    theme, Tooltip,
    Typography
} from "antd";
import {
    CalendarOutlined,
    CheckCircleOutlined, CloudUploadOutlined,
    CreditCardFilled, ExclamationCircleFilled, LinkOutlined,
    PlusCircleOutlined, UnorderedListOutlined,
} from "@ant-design/icons";
import {createFromPositions, deletePortfolio} from "@API/clientPortfolio";
import {setName, updatePortfolioFromPositions} from "@API/portfolio";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import StatsCard from "@components/retail/cards/StatsCard";
import NestedCard from "@components/retail/cards/NestedCard";
import CardHeader from "@components/retail/cards/CardHeader";
import FakeProgress
    from "@components/retail/portfolio/constructor/FakeProgress";
import PortfolioConstructor
    from "@components/retail/portfolio/constructor/PortfolioConstructor";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import SendToAdvisor from "@components/retail/portfolio/SendToAdvisor";
import {useParams} from "react-router-dom";
import PlaidLink from "@components/retail/portfolio/constructor/PlaidLink";


const {useToken} = theme;


const AccountTag = ({
                        tooltip = null,
                        icon = null,
                        color = null,
                        style = {},
                        children
                    }) => {
    const {token} = useToken();

    return <Tooltip title={tooltip} mouseEnterDelay={0.5}>
        <Tag
            bordered={false}
            icon={icon}
            color={color}
            style={{margin: 0, color: token.colorTextSecondary, ...style}}
        >
            {children}
        </Tag>
    </Tooltip>
}

export const AccountCard = ({account}) => {
    const {
        proposal,
        fetchProposal,
        setLoading,
        updateAiAnalyticalComments
    } = useHolisticoProposalContext();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openViewDrawer, setOpenViewDrawer] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(null);
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    const nPositions = (account?.positions?.length ?? 0)
        + (account?.positions?.length === 1 ? " position" : " positions");
    const marketValue = account?.calculated_values?.market_value;
    const lastUpdate = account?.calculated_values?.last_update;
    const institutionName = account?.auxiliary_data?.plaid_data?.institution_name;
    const linked = !!institutionName;

    const onEditClick = (e) => {
        setOpenDrawer(true);
    }

     const onViewClick = (e) => {
        setOpenViewDrawer(true);
    }

    const onDeleteClick = (e) => {
        setOpenProgress(true);
        deletePortfolio(proposal._id, 'cur', account._id, (result, error) => {
            if (!error) {
                if (proposal?.current_portfolios?.length > 1) {
                    fetchProposal(true, updateAiAnalyticalComments);
                } else {
                    fetchProposal(true);
                }
            } else {
                message.error("Something went wrong while deleting account!");
            }
            setOpenProgress(false);
        })
    }

    const updateAccount = (_positions, _name, _positionsChanged, _nameChanged) => {
        setTimeout(() => setRefreshTrigger(Math.random()), 500);
        setOpenDrawer(false);
        if (_positionsChanged && _nameChanged) {
            setOpenProgress(true);
            updatePortfolioFromPositions(account._id, _positions, (result, error) => {
                if (!error) {
                    setName(account._id, _name, (result, error) => {
                        if (error) {
                            message.error("Something went wrong while changing account title!")
                        }
                        fetchProposal(true, updateAiAnalyticalComments);
                    });
                } else {
                    // TODO: updateAiAnalyticalComments?
                    message.error("Something went wrong while updating the account!");
                    console.error(result);
                }
                setOpenProgress(false);
            })
        }
        if (_positionsChanged && !_nameChanged) {
            setOpenProgress(true);
            updatePortfolioFromPositions(account._id, _positions, (result, error) => {
                if (!error) {
                    fetchProposal(true, updateAiAnalyticalComments);
                } else {
                    // TODO: updateAiAnalyticalComments?
                    message.error("Something went wrong while updating the account!");
                    console.error(result);
                }
                setOpenProgress(false);
            })
        }
        if (!_positionsChanged && _nameChanged) {
            setLoading(true);
            setName(account._id, _name, (result, error) => {
                if (error) {
                    message.error("Something went wrong while changing account title!")
                }
                fetchProposal();
            });
        }
    }

    return <Flex
        vertical
        style={{
            maxWidth: "100%",
            background: hover ? token.colorBgHoverBlue : token.colorBgGrey,
            // background:  hover ? token.colorBgHoverBlue : "white",
            border: "1px solid rgb(220, 220, 220)",
            borderRadius: token.borderRadiusLG,
            padding: `${token.padding}px ${token.paddingLG}px ${token.paddingXXS}px ${token.padding}px `,
            flexGrow: 1,
            overflow: "hidden",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        <Flex justify={"space-between"} gap={"large"} style={{
            marginBottom: token.marginXS
        }}>
            <Typography.Text ellipsis style={{
                lineHeight: 1.2,
                fontWeight: token.fontWeightStrong,
            }}>
                <CheckCircleOutlined style={{
                    fontSize: token.fontSizeLG,
                    marginRight: token.marginSM
                }}/>
                {account.name}
            </Typography.Text>
            <Typography.Text style={{
                lineHeight: 1.2,
                fontWeight: token.fontWeightStrong,
                whiteSpace: "nowrap",
            }}>
                {ValueFormatter.currency(marketValue)}
            </Typography.Text>
        </Flex>
        <Flex gap={"small"} wrap={"wrap"}>
            <AccountTag
                icon={<UnorderedListOutlined/>}
                tooltip={`Account contains ${nPositions}`}
                color={hover ? "rgba(255, 255, 255, 0.5)" : "white"}
                // color={hover ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.03)"}
            >
                {nPositions}
            </AccountTag>
            {institutionName &&
                <AccountTag
                    icon={<LinkOutlined/>}
                    tooltip={`Linked to my account at ${institutionName}`}
                    color={hover ? "rgba(255, 255, 255, 0.5)" : "white"}
                    // color={hover ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.03)"}
                >
                    {institutionName}
                </AccountTag>
            }
            {lastUpdate &&
                <AccountTag
                    icon={<CalendarOutlined/>}
                    tooltip={`Last updated at ${dayjs(lastUpdate).format("hh:mm:ss on MMMM D, YYYY")}`}
                    color={hover ? "rgba(255, 255, 255, 0.5)" : "white"}
                    // color={hover ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.03)"}
                >
                    {dayjs(lastUpdate).format("MMMM D")}
                </AccountTag>
            }
        </Flex>
        <ConfigProvider theme={{
            components: {
                Button: {
                    colorLink: token.colorTextDescription,
                    colorError: token.colorTextDescription,
                    fontSize: token.fontSizeSM,
                    paddingInline: 0
                }
            }
        }}>
            <Flex gap={"middle"} align={"center"} style={{
                borderTop: `1px ${token.colorSplit} solid`,
                margin: `${token.margin}px -${token.paddingLG}px 0 -${token.padding}px`,
                padding: `0 ${token.padding}px`
            }}>
                <Popconfirm
                    title={"Are you sure to delete this account?"}
                    okText={"Yes"}
                    cancelText={"No"}
                    onCancel={(e) => {
                    }}
                    onConfirm={onDeleteClick}
                >
                    <Button type={"link"} danger>
                        Delete
                    </Button>
                </Popconfirm>
                {!linked && <>
                    <Divider type={"vertical"} style={{height: token.fontSizeLG}}/>
                    <Button onClick={onEditClick} type={"link"}>
                        Edit
                    </Button>
                </>}
                <Divider type={"vertical"} style={{height: 16}}/>
                <Button onClick={onViewClick} type={"link"}>
                    View
                </Button>
            </Flex>
        </ConfigProvider>
        <MobileDrawer
            open={openDrawer}
            onClose={() => {
                setTimeout(() => setRefreshTrigger(Math.random()), 500);
                setOpenDrawer(false);
            }}
            maskClosable={false}
            backButtonText={"Cancel"}
        >
            <PortfolioConstructor
                portfolio={account}
                allowEditName={true}
                applyButtonText={"Apply changes"}
                hoverable={false}
                onSubmit={updateAccount}
                refreshTrigger={refreshTrigger}
            />
        </MobileDrawer>
        <MobileDrawer
            open={openViewDrawer}
            onClose={() => {
                setOpenViewDrawer(false);
            }}
            maskClosable={false}
            backButtonText={"Back"}
        >
            <PortfolioConstructor
                portfolio={account}
                allowEditName={true}
                applyButtonText={"Apply changes"}
                hoverable={false}
                onSubmit={updateAccount}
                viewOnly={true}
            />
        </MobileDrawer>
        <FakeProgress
            tip={"Updating portfolio analytics..."}
            open={openProgress}
            time={30000}
        />
    </Flex>
}


export const AddAccountLink = ({type = "cur", onClick = null, style={}}) => {
    const {
        proposal,
        id,
        fetchProposal,
        updateAiAnalyticalComments
    } = useHolisticoProposalContext();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(null);
    const {token} = useToken();

    const portfolios = type === "cur"
        ? proposal?.current_portfolios
        : proposal?.proposed_portfolios;

    const createAccount = (_positions, _name) => {
        setTimeout(() => setRefreshTrigger(Math.random()), 500);
        setOpenDrawer(false);
        const postData = {
            positions: _positions,
            portfolio_name: _name,
            portfolio_type: type
        }
        setOpenProgress(true);
        createFromPositions(id, postData, (result, error) => {
            if (!error) {
                // TODO: update comments depending on type
                fetchProposal(true, updateAiAnalyticalComments);
            } else {
                message.error("Something went wrong while creating account!");
                console.error(result);
            }
            setOpenProgress(false);
        })
    }

    return <Flex vertical align={"flex-start"} style={{...style}}>
        <Button
            type={"link"}
            // icon={<PlusCircleOutlined/>}
            icon={<CloudUploadOutlined/>}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
                setOpenDrawer(true);
            }}
            style={{padding: 0}}
        >
            From file or asset search
        </Button>
        <Typography.Text type={"secondary"} style={{
            // fontSize: token.fontSizeSM,
            marginLeft: token.marginLG
        }}>
            Create account manually from file or asset search.
            {/*<br/>*/}
            {/*You can upload Excel, PDF, or image file, or search assets*/}
            {/*by hand.*/}
            {/*Account composition and portfolio analytics won't be updated*/}
            {/*automatically, but you can do it on the main page by hand.*/}
        </Typography.Text>
        <MobileDrawer
            open={openDrawer}
            onClose={() => {
                setTimeout(() => setRefreshTrigger(Math.random()), 500);
                setOpenDrawer(false);
            }}
            maskClosable={false}
            backButtonText={"Cancel"}
        >
            <PortfolioConstructor
                name={portfolios ? `Account ${portfolios.length + 1}` : "Main account"}
                tip={
                    "Fill portfolio positions to create account. " +
                    "You can either upload file with positions or " +
                    "use asset search to fill them manually."
                }
                allowEditName={true}
                applyButtonText={"Save"}
                hoverable={false}
                onSubmit={createAccount}
                refreshTrigger={refreshTrigger}
            />
        </MobileDrawer>
        <FakeProgress
            tip={"Updating portfolio analytics..."}
            open={openProgress}
            time={30000}
        />
    </Flex>
}


export const AddAccountChoice = ({onClick=null}) => {
    const {token} = useToken();

    return <Flex vertical gap={"middle"}>
        <PlaidLink onClick={onClick}/>
        <Divider style={{margin: `${token.marginXXS}px 0`}}/>
        <AddAccountLink onClick={onClick}/>
    </Flex>
}


const AddAccountModal = ({open, setOpen}) => {
    return <Modal
        title={"Add account(s)"}
        footer={null}
        closable={true}
        maskClosable={true}
        open={open}
        onCancel={() => setOpen(false)}
        afterClose={null}
        width={550}
    >
        <AddAccountChoice onClick={() => setOpen(false)}/>
    </Modal>
}


const AccountsCard = ({}) => {
    const {proposal} = useHolisticoProposalContext();
    const [openAddAccountModal, setOpenAddAccountModal] = useState(false);
    const {token} = useToken();
    const {externalId} = useParams();

    const cv = proposal?.p_bucket_cur?.calculated_values;
    const portfolios = proposal?.current_portfolios;
    const full_name = externalId ? proposal?.full_name : "My portfolio";

    return <Card
        hoverable
        style={{
            background: token.colorBgGrey,
            borderColor: "rgb(220, 220, 220)",
            cursor: "default"
        }}
    >
        <NestedCard background={"white"}>
            <CardHeader
                title={full_name}
                icon={<CreditCardFilled/>}
                controls={externalId ? null : <SendToAdvisor/>}
            />
            <Flex gap="small" align={"stretch"} style={{margin: "0 0 16px 0"}}
                  wrap={"wrap"}>
                <StatsCard
                    title="market value"
                    value={is_null(cv?.market_value) ? "-" : ValueFormatter.currency(cv.market_value)}
                />
                <StatsCard
                    title="positions"
                    value={is_null(cv?.unique_pos_count) ? "-" : ValueFormatter.int_number(cv.unique_pos_count)}
                />
                <StatsCard
                    title="distribution rate"
                    value={is_null(cv?.annual_dividend) ? "-" : ValueFormatter.int_percent(cv.annual_dividend)}
                />
                <StatsCard
                    title="expense ratio"
                    value={is_null(cv?.expense_ratio) ? "-" : ValueFormatter.int_percent(cv.expense_ratio)}
                />

            </Flex>
        </NestedCard>
        <Typography.Title level={5}>
            Accounts
        </Typography.Title>
        <Flex vertical gap={"small"} style={{marginBottom: 16}}>
            {portfolios &&
                portfolios.map((portfolio) => {
                    return <AccountCard key={portfolio._id}
                                        account={portfolio}/>
                })
            }
        </Flex>
        {/*<Flex vertical gap={"middle"} style={{marginLeft: token.margin}}>*/}
        {/*    <AddAccountLink/>*/}
        {/*    <PlaidLink/>*/}
        {/*</Flex>*/}
        <Button
            type={"link"}
            style={{padding: 0}}
            onClick={() => setOpenAddAccountModal(true)}
        >
            <PlusCircleOutlined/>
            Add account
        </Button>
        <AddAccountModal
            open={openAddAccountModal}
            setOpen={setOpenAddAccountModal}
        />
    </Card>
}


export default AccountsCard;
