import React, {useCallback, useEffect, useState} from "react";
import {cancel, deleteUser, editOrganization, editUser, getOrgAsync, getUser, getUserAsync} from "@API/users";
import {useNavigate} from 'react-router-dom';
import {
    Alert,
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Form,
    Input,
    message,
    Popconfirm,
    Row,
    Select,
    Spin,
    Typography
} from "antd";
import {DeleteOutlined, KeyOutlined} from '@ant-design/icons';

import "./Users.less";
import ChangePasswordModal from "@components/admin_panel/users/ChangePasswordModal";
import {getAuthConfiguration} from "@API/configuration";
import UsersList from "@components/admin_panel/users/UsersList";
import {useRequest, useUpdateEffect} from "ahooks";


const SubscriptionDetails = ({id}) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [])

    return (
        <div>
            <a href={"https://billing.stripe.com/p/login/test_cN2cOrcdz7UDaIwdQQ"}>Update subscription</a>
            {React.createElement("stripe-pricing-table", {
                "pricing-table-id": "prctbl_1Q9Wo5Rx0q6IEI1R30PlLn3D",
                "publishable-key":
                    "pk_test_51Q9Q6DRx0q6IEI1Rb1wXrxyHjyqGfuzipa2iGkVFMxFugqPiRPiETeCxEaLx7ufYDvLht6iWcOsxDTRJKq6I7maS00E66qHHZY",
            })}
        </div>


    )
}

export default SubscriptionDetails
