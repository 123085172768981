import React, { useState, useEffect, useCallback } from "react";
import {Button, Flex, message, theme, Typography} from "antd";
import {usePlaidLink} from "react-plaid-link";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {exchangePlaidPublicToken, getPlaidLinkToken} from "@API/clients";
import FakeProgress
    from "@components/retail/portfolio/constructor/FakeProgress";
import {LinkOutlined} from "@ant-design/icons";


const {useToken} = theme;


const PlaidLink = ({onClick=null, style={}}) => {
    const {id, fetchProposal} = useHolisticoProposalContext();
    const [openProgress, setOpenProgress] = useState(false);
    const [linkToken, setLinkToken] = useState(null);
    const {token} = useToken();

    const onSuccess = (publicToken) => {
        setOpenProgress(true);
        exchangePlaidPublicToken(id, publicToken, (response, error) => {
            if (error) {
                message.error("Error while linking account(s)");
                console.debug(error);
            } else {
                fetchProposal();
            }
            setOpenProgress(false);
        })
    };

    // Creates a Link token
    const createLinkToken = React.useCallback(async () => {
        // For OAuth, use previously generated Link token
        if (window.location.href.includes("?oauth_state_id=")) {
            const _linkToken = localStorage.getItem('link_token');
            setLinkToken(_linkToken);
        } else {
            getPlaidLinkToken(id, (response, error) =>{
                if (error) {
                    message.error("Error while creating Plaid link");
                    //error.data.message for "No investment accounts found!" error
                } else {
                    setLinkToken(response.link_token);
                    localStorage.setItem("link_token", response.link_token);
                }
            })
        }
    }, [setLinkToken]);

    let isOauth = false;
    const config = {
        token: linkToken,
        onSuccess: onSuccess,
    };

    // For OAuth, configure the received redirect URI
    if (window.location.href.includes("?oauth_state_id=")) {
        config.receivedRedirectUri = window.location.href;
        isOauth = true;
    }
    const {open, ready} = usePlaidLink(config);

    useEffect(() => {
        if (linkToken == null) {
            createLinkToken();
        }
        if (isOauth && ready) {
            open();
        }
    }, [linkToken, isOauth, ready, open]);

    return <>
        <Flex vertical align={"flex-start"} style={{...style}}>
            <Button
                type={"link"}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                    open();
                }}
                disabled={!ready}
                style={{padding: 0}}
            >
                <LinkOutlined/>
                From my institution
            </Button>
            <Flex vertical style={{marginLeft: token.marginLG}}>
                <Typography.Text type={"secondary"} style={{
                    // fontSize: token.fontSizeSM
                }}>
                    Link investment account(s) from your institution using Plaid.
                    {/*<br/>*/}
                    {/*You can choose institution and accounts you want to link.*/}
                    {/*Accounts composition and portfolio analytics will be updated*/}
                    {/*every night.*/}
                </Typography.Text>
                <Typography.Text type={"warning"} style={{
                    // fontSize: token.fontSizeSM
                }}>
                    This is a demo version of Plaid integration!
                    Only test accounts are available.
                    Fully functional version is coming soon.
                </Typography.Text>
            </Flex>
        </Flex>
        <FakeProgress
            tip={"Linking accounts and updating portfolio analytics..."}
            open={openProgress}
            time={60000}
        />
    </>
}

export default PlaidLink;
